<template>
  <div>
    <div class="header">
      <el-button type="primary" @click="$router.push('/information')">
        返回
      </el-button>
    </div>

    <div class="main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="标题：" prop="title">
          <div class="one">
            <el-input
              v-model="ruleForm.title"
              maxlength="30"
              show-word-limit
            ></el-input>
            <el-button type="primary" @click="dialogLook = true"
              >预览</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="发布类型：" prop="classification">
          <el-select
            v-model="ruleForm.classification"
            placeholder="请选择发布类型"
          >
            <el-option label="行业资讯" value="hyzx"></el-option>
            <el-option label="政策法规" value="zcfg"></el-option>
            <el-option label="专家解读" value="zjjd"></el-option>
            <el-option label="信用文化" value="xywh"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容简介" prop="remark">
          <div class="remark">
            <el-input
              type="textarea"
              v-model="ruleForm.remark"
              resize="none"
              rows="4"
              maxlength="120"
              show-word-limit
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="内容：" prop="content">
          <!-- <Editor
            @change="change"
            :html="ruleForm.content"
            class="editor"
          ></Editor> -->
          <wangeditor
            @change="change"
            :html="ruleForm.content"
            class="editor"
          ></wangeditor>
        </el-form-item>

        <el-form-item label="新闻封面图" prop="pic">
          <label class="file-upload">
            <input type="file" @change="changepic" id="file" />
            <span class="value" v-if="ruleForm.pic">
              <img
                id="show"
                :src="$utils.fileUrl(ruleForm.pic)"
                alt=""
              />
            </span>
            <i v-else class="el-icon-plus"></i>
          </label>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('form')"
            >确认修改</el-button
          >
          <!-- <el-button @click="resetForm('form')">清空</el-button> -->
          <el-button @click="$router.push('/information')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-dialog
        title="！！！请确认是否修改"
        v-model="dialogVisible"
        width="30%"
        center
      >
        <h4 style="text-align: center">标题：{{ ruleForm.title }}</h4>
        <div class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="hadeSubmit">修改</el-button>
        </div>
      </el-dialog>
    </div>

    <div class="dialogLook">
      <el-dialog v-model="dialogLook" width="80%" center>
        <h4 style="text-align: center">{{ ruleForm.title }}</h4>
        <div class="yulan" v-html="ruleForm.content"></div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Editor from "../../components/editor";
import wangeditor from "../../components/wangeditor";
import { useRouter } from "vue-router";
import api from "../../api";
import {
  onMounted,
  inject,
  onBeforeUnmount,
  ref,
  reactive,
  watch,
  toRefs,
} from "vue";

export default {
  components: { wangeditor },
  setup(props, content) {
    const router = useRouter();
    const id = router.currentRoute.value.params.id;

    const data = reactive({
      id: router.currentRoute.value.params.id,
      dialogVisible: false,
      dialogLook: false,
      ruleForm: {
        title: "",
        classification: "",
        content: "",
        imageUrl: "",
        remark: "",
        pic: null,
      },
      rules: {
        title: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: ["blur"],
          },
        ],
        classification: [
          {
            required: true,
            message: "请选择活动区域",
            trigger: ["blur", "change"],
          },
        ],
        pic: [
          {
            required: true,
            message: "请上传封面图片",
            trigger: ["blur", "change"],
          },
        ],
        remark: [
          {
            required: true,
            message: "请填写简介内容",
            trigger: ["change", "blur"],
          },
        ],
        content: [
          {
            required: true,
            message: "请填写内容",
            trigger: ["change", "blur"],
          },
        ],
      },
    });
    const change = (html) => {
      data.ruleForm.content = html;
    };

    onMounted(async () => {
      const res = await api.newsManagement.detailNew(id);
      if (res.data.code == 0) {
        data.ruleForm.title = res.data.data.title;
        data.ruleForm.classification = res.data.data.classification;
        data.ruleForm.content = res.data.data.content;
        data.ruleForm.remark = res.data.data.remark;
        data.ruleForm.pic = res.data.data.fileName;
      } else {
        // this.$message.error(res.msg);
        inject("$message").error(res.msg);
      }
    });

    return {
      change,
      ...toRefs(data),
    };
  },
  mounted() {
    // this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$api.newsManagement.detailNew(this.id);
      if (res.data.code == 0) {
        this.ruleForm.title = res.data.data.title;
        this.ruleForm.classification = res.data.data.classification;
        this.ruleForm.content = res.data.data.content;
        this.ruleForm.remark = res.data.data.remark;
        // this.ruleForm.pic = res.data.data.fileName;
      } else {
        this.$message.error(res.msg);
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogVisible = true;
        } else {
          return false;
        }
      });
    },
    // 清空
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 预览
    look() {
      //   if (this.ruleForm.title === "") {
      //     this.$message.error("请填写标题和内容");
      //     return false;
      //   } else {
      //     this.$router.push({
      //       path: `/authe_previewNews`,
      //     });
      //     localStorage.setItem("title", this.ruleForm.title);
      //     localStorage.setItem("content", this.ruleForm.content);
      //   }
    },
    // 提交
    async hadeSubmit() {
      let formData = new FormData();

      formData.append("title", this.ruleForm.title);
      formData.append("classification", this.ruleForm.classification);
      formData.append("content", this.ruleForm.content);
      formData.append("remark", this.ruleForm.remark);
      formData.append("pic", this.ruleForm.pic);

      const res = await this.$api.newsManagement.putNew(this.id, formData);

      if (res.code == 0) {
        this.$message.success("修改成功");
        this.$router.push("/information");
        this.dialogVisible = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传图片
    changepic() {
      var reads = new FileReader();
      const f = document.getElementById("file").files[0];

      const isJPG = f.type === "image/jpeg";
      const isPNG = f.type === "image/png";

      if (!isJPG && !isPNG) {
        this.$message.error("上传头像图片只能是 JPG或者PNG 格式!");
      } else {
        reads.readAsDataURL(f);
        reads.onload = function (e) {
          document.getElementById("show").src = this.result;
        };
        this.ruleForm.pic = f;
      }

      return isJPG || isPNG;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background: none;
  margin-bottom: 24px;
}
.main {
  background: #fff;
  padding: 20px;
}
.demo-ruleForm {
  display: flex;
  flex-direction: column;

  .el-input {
    width: 40%;
  }
  .one {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .remark {
    width: 40%;
  }
  .file-upload {
    width: 360px;
    height: 180px;
    cursor: pointer;
    line-height: 180px;
    text-align: center;
    position: relative;
    display: inline-block;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    &:hover {
      border-color: #999;
      i {
        color: #999;
      }
    }
    input {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 360px;
      height: 180px;
      opacity: 0;
      cursor: pointer;
      position: absolute;
    }
    i {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 360px;
      height: 180px;
      line-height: 180px;
      cursor: pointer;
      position: absolute;
      color: #dcdfe6;
      background: #fff;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      z-index: 9;
      border-radius: 4px;
    }
    img {
      width: 358px;
      height: 178px;
      position: absolute;
      border-radius: 4px;
      top: 1px;
      left: 1px;
      z-index: 10;
    }
  }
}
.dialog-footer {
  margin-top: 20px;
  text-align: center;
}

.yulan {
  /deep/p {
    margin: 10px 0;
    line-height: 1.5;
  }
}
</style>
